import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './style'
import VideoJS from '../Videojs/VideoJS'

export default function VideoWidget(){
  
return(
      
<Widget>
  <Container>
  <Widget.Block>
    <VideoJS />
    </Widget.Block>
    
  </Container>
</Widget>

)
}