import React, { useRef } from 'react';

function VideoJS() {
   
   return (
      <div>
         <div style={{textAlign: 'center', margin: '0 auto', width: '100%', minWidth: 200}}><div style={{position: 'relative', width: '100%', overflow: 'hidden', backgroundColor: 'transparent'}}><iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" allowtransparency="true" width="100%" frameborder="0" name="vocalvideo_1329" scrolling="no" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" src="https://vocalvideo.com/embed/v1/galleries/1329" title="Vocal Video" style={{background: 'transparent', position: 'relative', width: '1%', minWidth: '100%', height: 770, zIndex: 1, display: 'block'}}></iframe><script src="https://vocalvideo.com/embed/v1/host.js" defer="defer" type="text/javascript"></script></div></div>
      </div>
      
   )
};
export default VideoJS;